@font-face {
    font-family: "Rubik";
    src: local("Rubik"),
      url("./fonts/Rubik.ttf") format("truetype");
    font-weight: normal;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  body {
    font-family: Rubik;
    font-style: normal;
    color: #212529;
    font-size: 1rem;
    line-height: 1.625em;
  }
  
  h1 {
    font-family: Rubik;
    font-weight: 500;
    color: #0e2b5c;
    font-size: 3rem;
    line-height: 1.1em;
    letter-spacing: -1px;
  }
  
  h2 {
    font-family: Rubik;
    font-weight: 500;
    color: #0e2b5c;
    font-size: 2.25rem;
    line-height: 1.2em;
  }
  
  h3 {
    font-family: Rubik;
    font-weight: 500;
    color: #0e2b5c;
    font-size: 1.875rem;
    line-height: 1.2em;
  }
  
  h4 {
    font-family: Rubik;
    font-weight: 500;
    color: #0e2b5c;
    font-size: 1.25rem;
    line-height: 1.35em;
  }
  
  h5 {
    font-family: Rubik;
    font-weight: 500;
    color: #0e2b5c;
    font-size: 1.15rem;
    line-height: 1.35em;
  }
  
  h6 {
    font-family: Rubik;
    font-weight: 500;
    color: #ff9300;
    text-transform: none;
    font-size: 1rem;
    line-height: 1.35em;
  }

.logo {
    width: 75px;
    height: auto;
}

.site-topbar {
    background-color: #1f263b;
    font-style: normal;
    color: rgba(255, 251, 251, 0.5);
    font-size: 13px;
    line-height: 150%;
}

.site-topbar {
    z-index: 999;
    position: relative;
}

.Menu-Button {
    margin-left: 3px;
}

.wrap {
    width: 1170px;
    max-width: 90%;
}

.wrap {
    margin: 0 auto;
    position: relative;
}

.site-topbar {
    font-size: 13px;
    line-height: 150%;
}

.site-topbar .site-topbar-inner {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.site-topbar .topbar-text {
    flex-grow: 1;
}

.site-topbar .topbar-text ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.site-topbar .topbar-text ul li {
    display: inline-block;
    margin-right: 40px;
}

.site-topbar .topbar-text ul li i {
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
}

body i[class*=ion-].size-48 {
    font-size: 48px;
}

.site-topbar .topbar-text ul li .content {
    display: inline-block;
    vertical-align: middle;
    line-height: 100%;
}

.site-topbar a {
    color: #ffffff;
}

.site-topbar .topbar-menu .menu-top {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-left: 0;
    float: left;
    margin: 0 15px;
}

.topbar-menu .menu-top .content {
    display: block;
    padding: 0px 15px;
    align-items: center;
}

.site-topbar .topbar-menu .menu-top li {
    position: relative;
}

.site-topbar .topbar-menu .menu-top {
    padding: 15px 0;
    display: flex;
    text-align: center;
}

.site-topbar .topbar-text ul li .content span.action {
    font-size: 1.125rem;
}

.site-topbar .topbar-text ul li .content span {
    display: block;
}

.site-topbar .topbar-text ul li .content span.text {
    -webkit-opacity: .7;
    -khtml-opacity: .7;
    -moz-opacity: .7;
    opacity: .7;
    -ms-filter: progid: DXImageTransform.Microsoft.Alpha(opacity=70);
    filter: alpha(opacity=70);
    margin-bottom: 6px;
}

.servers-card {
    height: 300px;
}

.support-card {
    height: 300px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}