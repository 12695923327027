.scrolling-list {
    overflow: scroll;
    height: 425px;
}

.scrolling-list::-webkit-scrollbar {
    display: none;
  }

.top-card {
    height: 500px;
    max-height: 500px;
}

.table-header {
    background: white;
    color:black;
}

.button-reset {
    position: relative;
    bottom: -150px;
}


/* Make tr's in both thead & tbody into tables w/ fixed width layout */